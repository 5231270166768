import Carousel from 'react-bootstrap/Carousel'

const slides = [
  { id: 0, text: 'Press the Spacebar to bring up the floor selection menu' },
  { id: 1, text: 'Use a wired gaming controller or mouse and keyboard to move around the space.' },
  { id: 2, text: 'Tip 3' },
  { id: 3, text: 'Tip 4' },
]

export default function CreateHints(...props) {
  return (
    <Carousel id="hints" className="hint" controls={false} indicators={false}>
      <Carousel.Item interval={10000}>
        <p>Press the Spacebar to bring up the floor selection menu</p>
      </Carousel.Item>
      <Carousel.Item interval={10000}>

        <p>Use a wired gaming controller or mouse and keyboard to move around the space</p>
      </Carousel.Item>
      <Carousel.Item interval={10000}>

        <p>Press Escape at any time to exit fullscreen mode</p>
      </Carousel.Item>
    </Carousel>
  )


}