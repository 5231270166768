import { Spring } from 'react-spring/renderprops'

export default function createBrandLogo(props) {
    return (
        <Spring delay={400}
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
        >
            {({ opacity }) => (
                <div className="brand" style={{ opacity }}><img src={props.logo} className="brand-image" alt="brand"></img></div>
            )}
        </Spring>
    )
}