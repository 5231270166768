import Container from 'react-bootstrap/Container'
import React, { useState, useEffect, } from 'react';
import { useSpring, animated } from 'react-spring'
import { Player, FS_SDK_EVENTS_NAME } from 'furioos-sdk';

// Import Components
import Hints from './Hints'
import BrandLogo from './BrandLogo'
import PoweredBy from './PoweredBy'
import PageBackground from './PageBackground'



// create the webPlayer Page
export default function PlayerPage(props) {

    //Styling for hovering over play button
    const [{ scale, color }, set] = useSpring(() => ({ scale: 1, color: "rgba(0,0,0,0" }));

    useEffect(() => {
        const options = {
            whiteLabel: true,
            hideToolbar: true,
            hideTitle: true,
            hidePlayButton: true,
        };
        const player = new Player(props.playerid, 'streamlinePlayer', options);

        //create fullscreen bind for F key
        window.addEventListener('keypress', function (e) {
            if (e.code === 'KeyF') {
                toggleFullScreen();
            }
        }, false);


        document.getElementById('fsToggle').addEventListener('click', function () {
            toggleFullScreen();
        });

        //fullscreen toggle
        function toggleFullScreen() {
            if (!document.fullscreenElement) {
                document.getElementById('streamlinePlayer').requestFullscreen();
                player.maximize();

            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                    player.minimize();
                }
            }
        }

        player.onLoad(function () {
            document.getElementById('button_start').classList.remove('disabled');

        });
        player.onAppInstallProgress(function (data) {
            console.log("App Install Status: " + data.status + ", Progress: " + data.progress);
        });
        player.onAppStart(function () {
            document.getElementById('hints').classList.add('hidden');
            document.getElementById('installing-video').classList.add('hidden');
            document.getElementById('installing-video').pause();
            document.getElementById('furioos-sdk-iframe').classList.remove('hidden');
        });
        //Start button
        document.getElementById('button_start').addEventListener('mouseup', () => {
            player.start();
            //change this to animate in and out... 
            // document.getElementById('player-background').classList.add('hidden');
            document.getElementById('tagline').classList.add('hidden');
            document.getElementById('startButton').classList.add('hidden');
            document.getElementById('streamlinePlayer').classList.remove('hidden');
            document.getElementById('streamlinePlayer').requestFullscreen();
            document.getElementById('fsToggle').classList.remove('hidden');
            document.getElementById('installing-video').classList.remove('hidden');
            //document.getElementById('installing-video').requestFullscreen();
            document.getElementById('furioos-sdk-iframe').classList.add('hidden');
        })

        if (props.backgroundType === "video") {
            var projectVideo = document.getElementById('page-background');
            projectVideo.autoplay = true;
            projectVideo.loop = true;
            projectVideo.load();
            projectVideo.play();
        }
    })

    return (

        <Container className="player-page">
            {/*
            {/*<img id="playerBackground" className="background-image" src={props.background} alt="" />*/}
            {/*<video id="playerBackground" className="background-image" alt="backgroundVideo" src={props.background} type="video/mp4" autoPlay={true} loop={true} muted={true} controls=''>
                {/*<source src={props.background} type="video/mp4"></source>*/}
            {/*</video>*/}
            <PageBackground {...props} />
            <BrandLogo {...props} />
            <div id="InfoContainer" className="info-container">

                <div id="tagline" className="tagline">
                    {props.tagline}
                </div>
                <div id="startButton" style={{ minWidth: '160px', minHeight: '200px' }} >
                    <animated.div
                        id="button_start" className="btn-start disabled"
                        onMouseEnter={() => set({ scale: 1.1, color: "rgba(0,0,0,0.2)" })}
                        onMouseLeave={() => set({ scale: 1, color: "rgba(0,0,0,0" })}
                        style={{ transform: scale.interpolate((scale) => `scale(${scale})`) }}>
                        <div>
                            <div className="outer-circle"></div>
                            <animated.div className="inner-circle" style={{ background: color }}></animated.div>
                            <div className="triangle"></div>
                        </div>
                        <p>Begin your Experience</p>
                    </animated.div>
                </div>
                <div id="streamlinePlayer" className="hidden">
                    <Hints />
                    <video id="installing-video" className="hidden" alt="Video preview of the architecture project" src={props.installingVideo} type="video/mp4" autoPlay={true} loop={true} muted={true} controls=''></video>
                </div>
                <div id="fsToggle" className="fullscreen-toggle hidden"><i id="playerMaximizeButton" className="fas fa-expand"></i> Fullscreen</div>
            </div>
            <PoweredBy />
        </Container >
    );
}