import { useEffect } from "react";

export default function createPageBackground(props) {
    if (props.backgroundType == "video") {
        return (<video id="page-background" className="background-image" alt="Preview of the architecture project" src={props.background} type="video/mp4" autoPlay={true} loop={true} muted={true} controls=''></video>);
    }
    else if (props.backgroundType == "image") {
        return (<img id="page-background" className="background-image" src={props.background} alt="Preview of the architecture project" />);
    }
    else {
        return <div id="page-background" className="background-image" ></div>;
    }
}