import React from 'react';
import { ThemeProvider } from 'react-bootstrap';
import PlayerPage from '../components/PlayerPage';

class Osh extends React.Component {
  //Create props for specific client page
  constructor(props) {
    super(props);
    this.state = {
      switchTagline: true,
      switchButton: true,
      switchPlayer: false
    };

    this.name = 'osh';
    this.background = require('../img/' + this.name + '/background.jpg');
    this.backgroundType = 'image';
    this.logo = require('../img/' + this.name + '/logo.png');
    //this.playerID = 'TcB9RDAQ6vxoBAuYh'; //used for local testing
    this.playerID = '831.003'; // for streamline.soluis.com
    this.installingVideo = require('../videos/' + this.name + '/OSH_Preview_Video.mp4');

  }

  //Create content for the tagline section
  renderTagline() {
    return (
      <>
        <p>Reading’s transformational development:</p>
        <h1>Explore using Streamline</h1>
      </>
    )
  }

  render() {
    return (
      <PlayerPage name={this.name} background={this.background.default} backgroundType={this.backgroundType} logo={this.logo.default} tagline={this.renderTagline()} playerid={this.playerID} installingVideo={this.installingVideo.default} />
    );
  }
}

export default Osh;

