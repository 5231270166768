import React from 'react';
import PlayerPage from '../components/PlayerPage';

class Morrisons extends React.Component {
  //Create props for specific client page
  constructor(props) {
    super(props);
    this.state = {
      switchTagline: true,
      switchButton: true,
      switchPlayer: false
    };

    this.name = 'morrisons';
    //this.background = require('../img/' + this.name + '/background.jpg');
    this.background = require('../videos/' + this.name + '/MorrisonsVid_01.mp4');
    this.backgroundType = 'video';
    this.logo = require('../img/' + this.name + '/logo.png');
    //this.playerID = 'KeuZCj8tnr4XhzJ8T'; //used for local testing
    this.playerID = 'ywG84GE6p75xcggaM'; // for streamline.soluis.com

  }

  //Create content for the tagline section
  renderTagline() {
    return (
      <>
        <p>Morrisons Market Street:</p>
        <h1>Explore using Streamline</h1>
      </>
    )
  }

  render() {
    return (
      <PlayerPage name={'this.name'} background={this.background.default} backgroundType={this.backgroundType} logo={this.logo.default} tagline={this.renderTagline()} playerid={this.playerID} />
    );
  }
}

export default Morrisons;

