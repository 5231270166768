import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import DaviesSt from './Pages/DaviesSt';
import Osh from './Pages/Osh';
import Fulham from './Pages/fulham';
import Morrisons from './Pages/Morrisons';

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/morrisons" component={Morrisons} />
            <Route exact path="/davies-st" component={DaviesSt} />
            <Route exact path="/osh" component={Osh} />
            <Route exact path="/fulham" component={Fulham} />
          </Switch>
        </Router>
      </header>

    </div>
  );

  function Home() {
    return (<div><h1><i>Streamline</i></h1><p>By Soluis</p></div>);
  }
}

export default App;
